import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const GlobalStyle = createGlobalStyle`
  body {
    -webkit-text-size-adjust: 100%;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    height: 42px;
    box-sizing: border-box;
    background-color: #EFF0F4 !important;
    -webkit-text-fill-color: rgb(80, 84, 99);
    -webkit-box-shadow: none;
  }
  .currency {
    font-family: "Helvetica Neue", sans-serif;
  }
  .ps__rail-x {
    height: 13px;
    border-top: 1px solid #e2e2ea;
  }
  .ps__rail-x:before {
    content: "";
    height: 16px;
    border-top: 1px solid #e2e2ea;
    background-color: #f5f6f6 !important;
    position: absolute;
    top: -17px;
    left: 0;
    width: 100%;
  }
  .ps__thumb-x {
    background-color: #115cef !important;
    border-radius: 0 !important;
    height: 12px !important;
    bottom: 0;
  }
  .ps--active-x > .ps__rail-x {
    background-color: #f5f6f6 !important;
    opacity: 1 !important;
    border-top: 1px solid #e2e2ea;
  }
  .fullpage-layout-wrapper {
    overflow-x: hidden;
  }
`;

export default GlobalStyle;
