import { ApolloClient } from 'apollo-client';
import { getDataFromTree } from '@apollo/react-ssr';
import { InMemoryCache } from 'apollo-cache-inmemory';
import withApollo from 'next-with-apollo';
import { createHttpLink } from 'apollo-link-http';
import fetch from 'isomorphic-unfetch';

const GRAPHQL_URL = `${
  typeof window === 'undefined' ? process.env.BACKEND_URL : window.BACKEND_URL
}/graphql`;

const link = createHttpLink({
  fetch, // Switches between unfetch & node-fetch for client & server.
  uri: GRAPHQL_URL,
});

// Export a HOC from next-with-apollo
// Docs: https://www.npmjs.com/package/next-with-apollo
export default withApollo(
  ({ initialState, ctx }) =>
    new ApolloClient({
      link: link,
      ssrMode: Boolean(ctx),
      cache: new InMemoryCache({ addTypename: false })
        //  rehydrate the cache using the initial data passed from the server:
        .restore(initialState || {}),
    }),
  {
    getDataFromTree,
  }
);
