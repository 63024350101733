import { createGlobalStyle } from 'styled-components';

const WebimStyle = createGlobalStyle`
.webim-root {
    top: auto !important;
    left: auto !important;
    bottom: 110px;
    right: 20px;
}
.webim-root.webim-mobile-mode,
.webim-root.webim-separate-mode {
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
}
.webim-powered-by-webim {
    display: none;
}
.webim-visitor-message span,
.webim-visitor-message a,
.webim-visitor-message .webim-file-size {
    color: #fff !important;
}
.webim-visitor-message .webim-file-size {
    opacity: .5;
}
.webim-header {
    padding-left: 100px !important;
    cursor: default !important;
}
.webim-header::after {
    content: '';
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
    width: 32px;
    height: 32px;
    padding: 8px;
    background: #fff;
    border-radius: 12px;
    background-image: url('/images/logo/payday-logo-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
}
.webim-root .webim-operator-block > .webim-operator {
    margin-left: 0 !important;
}
.webim-root .webim-no-operator-block {
    padding-left: 0 !important;
}
.webim_button {
    right: 20px !important;
    bottom: 20px !important;
}
.webim_button img {
    display: block;
    width: 48px;
    height: 48px;
}
.webim-avatar {
    display: none !important;
}
.webim_button.hidden {
    display: none !important;
}
.webim-form-control textarea,
.webim-root .webim-dialogue-message textarea,
.webim-root .webim-dialogue-message .webim-textarea-ext {
    font-size: 16px !important;
}
.webim-popups-block {
    padding-top: 7px;
}
.webim-popups-block {
    padding-top: 7px !important;
}
.webim-button-corner {
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 26, 77, 0.1), 0px 6px 28px rgba(0, 26, 77, 0.12);
    border-radius: 16px;
    padding: 12px !important;
}
.webim-button-row {
    flex-wrap: wrap;
    flex-direction: row-reverse;
}
.webim-button-row .webim-btn {
    white-space: nowrap !important;
    flex-grow: 0 !important;
    flex-basis: auto !important;
    margin: 0 4px 10px;
    background: #fff !important;
    border: 2px solid #115CEF;
    box-sizing: border-box;
    border-radius: 16px;
}
.webim-button-row .webim-btn span {
    color: #000 !important;
}
.webim-chat-block {
    margin-right: 0 !important;
}
.webim-control-block {
    position: relative !important;
    z-index: 1000 !important;
}
.webim-root.webim-desktop-mode > .webim-control-block {
    position: absolute !important;
    bottom: -94px;
    right: 51px;
}
.webim-root.webim-desktop-mode > .webim-control-block .webim-action-close {
    padding: 12px;
    width: 48px !important;
    height: 48px !important;
    background: #fff url('/images/icons/icon_close.svg') 50% 50% no-repeat;
    border-radius: 16px !important;
    border: none !important;
    color: #fff;
    font-size: 18px;
}
.webim-root.webim-desktop-mode > .webim-control-block .webim-ico.webim-ico-close {
  opacity: 0;
}
.webim-message-body[data-webim-custom-style="messageVisitor"] span {
    color: #fff !important;
}
.webim-message div[data-webim-region="button-rows"]{
  display: none;
}
.webim-message:last-child div[data-webim-region="button-rows"]{
  display: block;
}
`;

export default WebimStyle;
