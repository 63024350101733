import React from 'react';
import App from 'next/app';
import Head from 'next/head';

import { ApolloProvider } from '@apollo/react-hooks';

import withData from '../utils/apollo-client';
import { initSentry } from '../utils/sentry';

import GlobalStyle from '../styles/global';
import WebimStyle from '../styles/webim';

initSentry();

class MyApp extends App {
  render() {
    const { Component, pageProps, apollo } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    return (
      <React.Fragment>
        <Head>
          <title>Payday</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <meta name="description" content="Payday" />
          <meta property="og:title" content="PayDay" />
          <meta
            property="og:description"
            content="PayDay позволяет сотрудникам следить за заработанными деньгами и получать их в любое удобное время."
          />
          <meta property="og:url" content="https://payday.ru" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="/images/logo/logo-black.jpg" />
        </Head>
        <GlobalStyle />
        <WebimStyle />
        <ApolloProvider client={apollo}>
          <Component {...modifiedPageProps} />
        </ApolloProvider>
      </React.Fragment>
    );
  }
}

export default withData(MyApp);
